<template>
  <ConfirmDialog></ConfirmDialog>
  <Toast />
  <ul class="list-none p-0 m-0 flex align-items-center font-medium">
    <li>
      <a class="text-500 no-underline line-height-3 cursor-pointer">Ventas</a>
    </li>
    <li class="px-2">
      <i class="pi pi-angle-right text-500 line-height-3"></i>
    </li>
    <li>
      <span class="text-900 line-height-3 active">Detalle Ventas</span>
    </li>
  </ul>
  <div class="surface-section px-4 py-5 md:px-12 lg:px-12">
    <div
      class="flex align-items-start flex-column lg:justify-content-between lg:flex-row"
    >
      <div>
        <div class="font-medium text-3xl text-900">
          Administrar Ventas
          <Badge size="xlarge" severity="success"> {{ totalRecords }}</Badge>
        </div>
      </div>
      <div>
        <Button
          icon="pi pi-refresh"
          class="p-button-info p-button-lg p-button-outlined mr-1"
          @click="cargarVentas"
        />
        <Button
          label="NUEVA VENTA"
          v-if="'Venta Crear' in auth.user.permissions"
          class="p-button-outlined p-button-lg"
          icon="pi pi-plus"
          v-tooltip.top="'Nueva Venta'"
          @click="nuevaVenta"
        />
      </div>
    </div>
    <div class="mt-2">
      <DataTable
        ref="ventas"
        :value="ventas"
        dataKey="id"
        :rows="15"
        :filters="buscar"
        :loading="cargando"
        :lazy="true"
        :totalRecords="totalRecords"
        :paginator="true"
        v-model:filters="buscar"
        class="p-datatable-sm"
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        :rowsPerPageOptions="[15, 100, 500, 1000]"
        currentPageReportTemplate="Mostrando {first} de {last} de {totalRecords} Ventas"
        responsiveLayout="scroll"
        stripedRows
        showGridlines
        selectionMode="single click"
        @page="onPage($event)"
        @sort="onSort($event)"
        @filter="onFilter($event)"
        filterDisplay="menu"
        v-model:expandedRows="expandedRows"
      >
        <template #header v-if="'Producto Actualizar' in auth.user.permissions">
          <div class="flex flex-wrap justify-content-end gap-2">
            <Button
              icon="pi pi-minus"
              label="Colapsar Todo"
              class="p-button-info p-button-raised"
              @click="collapseAll"
            />
            <Button
              icon="pi pi-plus"
              label="Expandir Todo"
              class="p-button-info p-button-raised"
              @click="expandAll"
            />
          </div>
        </template>
        <template
          #expansion="slotProps"
          v-if="'Producto Actualizar' in auth.user.permissions"
        >
          <div class="p-3">
            <h5>
              <span style="border-bottom: 1px solid #ccc">
                DETALLE DE VENTA:
                <strong
                  >{{ slotProps.data.id }} -
                  {{ slotProps.data.razon_social }}</strong
                >
              </span>
            </h5>
            <DataTable
              :value="slotProps.data.productos"
              class="p-datatable-sm"
              responsiveLayout="scroll"
              stripedRows
              showGridlines
              selectionMode="single click"
              @row-dblclick="editarProducto($event.data)"
            >
              <Column field="id" header="COD_PROD" class="text-right"></Column>
              <Column
                field="descripcion"
                header="PRODUCTO/DESCRIPCIÓN"
                style="width: 300px; font-weight: bold"
              ></Column>
              <Column header="CANTIDAD" class="text-right">
                <template #body="slotProps">
                  {{ slotProps.data.pivot.cantidad }}
                </template>
              </Column>
              <Column header="PRECIO" class="text-right">
                <template #body="slotProps">
                  {{ slotProps.data.pivot.precio }}
                </template>
              </Column>
              <Column header="SUBTOTAL" class="text-right">
                <template #body="slotProps">
                  {{
                    slotProps.data.pivot.cantidad * slotProps.data.pivot.precio
                  }}
                </template>
              </Column>
              <Column header="DESCUENTO" class="text-right">
                <template #body="slotProps">
                  {{ slotProps.data.pivot.descuento_neto }}
                </template>
              </Column>
              <Column header="TOTAL" class="text-right">
                <template #body="slotProps">
                  {{
                    slotProps.data.pivot.cantidad *
                      slotProps.data.pivot.precio -
                    slotProps.data.pivot.descuento_neto
                  }}
                </template>
              </Column>
            </DataTable>
          </div>
        </template>
        <template #loading>
          <div class="flex align-items-center justify-content-center">
            <ProgressSpinner />
          </div>
        </template>
        <template #empty>
          <span class="flex align-items-center justify-content-center p-invalid"
            >No existen Registros!</span
          ></template
        >
        <!-- <Column header="#">
          <template #body="slotProps">
            {{ 1 + slotProps.index }}
          </template>
        </Column> -->
        <Column
          expander
          style="width: 2rem"
          v-if="'Producto Actualizar' in auth.user.permissions"
        ></Column>
        <Column
          field="id"
          header="NRO"
          filterMatchMode="startsWith"
          :showFilterMatchModes="false"
          :class="'text-right'"
          style="font-weight: bold"
        >
          <template #filter="{ filterModel, filterCallback }">
            <InputText
              type="text"
              v-model="filterModel.value"
              @keydown.enter="filterCallback()"
              class="p-column-filter"
              placeholder="CÓD..."
            />
          </template>
        </Column>
        <Column field="nombre_usuario_caja" header="VENDEDOR"> </Column>
        <Column field="razon_social" header="RAZÓN SOCIAL">
          <template #body="{ data }">
            <span v-if="data.caja.tipo_negocio == 2">
              <span>{{ data.razon_social }}</span>
              <strong>-Placa: </strong>
              <span>{{ data.placa_vehiculo ?? "-" }}</span>
            </span>
            <span v-else>
              {{ data.razon_social }}
            </span>
          </template>
        </Column>
        <Column field="fecha_venta" header="F.VENTA"> </Column>
        <Column
          v-if="false"
          field="nit"
          header="NIT/CI"
          filterMatchMode="startsWith"
          :showFilterMatchModes="false"
          :class="'text-right'"
        >
          <template #filter="{ filterModel, filterCallback }">
            <InputText
              type="text"
              v-model="filterModel.value"
              @keydown.enter="filterCallback()"
              class="p-column-filter"
              placeholder="NIT..."
            />
          </template>
        </Column>
        <Column
          v-if="false"
          field="descuento"
          header="DESC."
          class="text-right"
        >
          <template #body="{ data }">
            {{ convertirNumeroGermanicFormat(data.descuento) }}
          </template>
        </Column>
        <Column
          field="total_cancelar"
          header="T.VENTA"
          class="text-right"
          style="font-weight: bold"
        >
          <template #body="{ data }">
            {{ convertirNumeroGermanicFormat(data.total_cancelar) }}
          </template>
        </Column>
        <Column field="calculo_efectivo" header="EFECT." class="text-right">
          <template #body="{ data }">
            {{ convertirNumeroGermanicFormat(data.calculo_efectivo) }}
          </template>
        </Column>
        <Column field="total_venta_qr" header="QR" class="text-right">
          <template #body="{ data }">
            {{ convertirNumeroGermanicFormat(data.total_venta_qr) }}
          </template>
        </Column>
        <Column field="total_venta_tarjeta" header="TARJETA" class="text-right">
          <template #body="{ data }">
            {{ convertirNumeroGermanicFormat(data.total_venta_tarjeta) }}
          </template>
        </Column>
        <Column
          field="facturado"
          header="ENVIADO"
          class="text-center"
          :sortable="true"
          v-if="'Venta Facturar' in auth.user.permissions"
        >
          <template #body="{ data }">
            <span
              class="product-badge status"
              :class="{
                'status-outofstock': data.facturado === 0,
                'status-instock': data.facturado === 1,
              }"
            >
              {{ textoFacturado(data.facturado) }}
            </span>
          </template>
        </Column>
        <Column
          v-if="'Venta FactIndicador' in auth.user.permissions"
          field="fact_indicador"
          header="FACTURA"
          class="text-center"
        >
          <template #body="{ data }">
            <span
              class="product-badge status"
              :class="{
                'status-outofstock': data.fact_indicador === 0,
                'status-instock': data.fact_indicador === 1,
              }"
            >
              {{ textoFacturado(data.fact_indicador) }}
            </span>
          </template>
        </Column>
        <Column field="nombre_medico" header="REALIZÓ" v-if="tipo_negocio == 2">
          <template #body="{ data }">
            {{ data.nombre_medico ?? "-" }}
          </template>
        </Column>
        <Column field="estado" header="ESTADO">
          <template #body="{ data }">
            <span :class="'product-badge ' + claseEstado[data.estado]">
              {{ data.estado_texto }}
            </span>
          </template>
        </Column>
        <Column header="ACCIONES">
          <template #body="slotProps">
            <Button
              icon="pi pi-ellipsis-v"
              class="p-button-icon-only p-button-raised"
              v-tooltip.top="'Ver Acciones'"
              @click="$refs['menu' + slotProps.data.id].toggle($event)"
            ></Button>
            <Menu
              :ref="'menu' + slotProps.data.id"
              :popup="true"
              :model="acciones(slotProps.data)"
            ></Menu>
          </template>
        </Column>
      </DataTable>
      <Dialog
        v-model:visible="mostrar_qr"
        modal
        header="FACTURA QR"
        :style="{ width: '200px' }"
      >
        <img
          :src="'data:image/png;base64, ' + qr"
          alt="QR"
          :style="{ width: '150px', height: '150px' }"
        />
      </Dialog>
      <Dialog
        v-model:visible="motivoAnulacionDialog"
        modal
        header="MOTIVO DE ANULACIÓN"
        :style="{ width: '400px' }"
      >
        <div class="p-fluid">
          <div class="p-field">
            <label for="motivo_anulacions"
              ><strong>SELECCIONE MOTIVO ANULACI&Oacute;N:</strong></label
            >
            <Dropdown
              id="motivo_anulacions"
              v-model="motivoAnulacionSelected"
              :options="motivo_anulacions"
              optionLabel="descripcion"
              optionValue="codigoClasificador"
              placeholder="Seleccione Motivo Anulación"
            />
          </div>
        </div>
        <div class="flex justify-content-end mt-4">
          <Button
            label="CANCELAR"
            icon="pi pi-times"
            class="p-button-success mr-2 p-button-lg"
            @click="motivoAnulacionDialog = false"
          />
          <Button
            label="ANULAR FACTURA"
            icon="pi pi-trash"
            class="p-button-danger p-button-lg"
            :loading="enviando"
            @click="anularVentaFactura(anularVentaId)"
          />
        </div>
      </Dialog>
    </div>
  </div>
  <VentaFacturar
    :show="ventafacturaDialog"
    :ventaFactura="ventaFactura"
    @closeModal="ocultarDialog"
    @actualizarListado="cargarVentas"
  >
  </VentaFacturar>
  <VentaMetodoPago
    :show="metodoPagoDialog"
    :metodoPago="metodoPago"
    @closeModal="cerrarModalMetodoPago"
    @actualizarListado="cargarVentas"
  >
  </VentaMetodoPago>
  <ProductoCreate
    :mostrar="mostrarProductoModal"
    :producto="producto"
    @closeModal="cerrarModal"
    @actualizarListadoProductos="cargarVentas"
  >
  </ProductoCreate>
</template>

<script>
import { FilterMatchMode } from "primevue/api";
import VentaService from "@/service/VentaService";
import VentaFacturar from "@/module/ventas/VentaFacturar.vue";
import { useAuth } from "@/stores";
import VentaMetodoPago from "@/module/ventas/VentaMetodoPago.vue";
import ProductoCreate from "@/module/productos/ProductoCreate.vue";

export default {
  name: "VentasList",

  components: {
    VentaFacturar,
    VentaMetodoPago,
    ProductoCreate,
  },
  data() {
    return {
      ventas: [],
      totalRegistros: 0,
      totalRecords: 0,
      cargando: false,
      buscar: {
        global: { matchMode: FilterMatchMode.CONTAINS, value: null },
        id: { value: null, matchMode: FilterMatchMode.EQUALS },
        nit: { value: null, matchMode: FilterMatchMode.EQUALS },
      },
      claseEstado: ["status-outofstock", "status-instock", "status-lowstock"],
      qr: "",
      mostrar_qr: false,
      ventaFactura: {},
      ventafacturaDialog: false,
      metodoPagoDialog: false,
      metodoPago: {},
      motivo_anulacions: [],
      motivoAnulacionSelected: {},
      motivoAnulacionDialog: false,
      anularVentaId: null,
      enviando: false,
      lazyParams: {},
      expandedRows: [],
      mostrarProductoModal: false,
      producto: {},
      tipo_negocio: 1,
    };
  },
  auth: null,
  VentaMetodoPago: null,
  created() {
    this.auth = useAuth();
    this.ventaService = new VentaService();
  },
  mounted() {
    this.cargarMotivoAnulacion();
    this.lazyParams = {
      /*  lazy: true, */
      first: 0,
      rows: this.$refs.ventas.rows,
      sortField: null,
      sortOrder: null,
      filters: this.buscar,
    };
    this.cargarVentas();
  },
  methods: {
    editarProducto(datos) {
      this.producto = { ...datos };
      this.producto.estado = { label: datos.estado_texto, value: datos.estado };
      this.producto.stock_minimo = parseInt(this.producto.stock_minimo || 0);
      this.producto.stock_maximo = parseInt(this.producto.stock_maximo || 0);
      this.producto.precio_sugerido = parseFloat(
        this.producto.precio_sugerido || 0
      );
      this.producto.precio_compra = parseFloat(
        this.producto.precio_compra || 0
      );
      this.producto.stock_actual = parseInt(this.producto.stock_actual || 0);
      this.producto.tipo_catalogo = parseInt(this.producto.tipo_catalogo || 1);
      this.mostrarProductoModal = true;
    },
    cerrarModal() {
      this.mostrarProductoModal = false;
      this.producto = {};
    },
    onPage(event) {
      this.lazyParams = event;
      this.cargarVentas();
    },
    onSort(event) {
      this.lazyParams = event;
    },
    onFilter() {
      this.lazyParams.filters = this.buscar;
      this.cargarVentas();
    },
    cargarMotivoAnulacion() {
      this.ventaService.motivo_anulacions().then((data) => {
        this.motivo_anulacions = data.motivo_anulacions;
        this.motivoAnulacionSelected = this.motivo_anulacions.length
          ? this.motivo_anulacions[0].codigoClasificador
          : {};
      });
    },
    acciones(datos) {
      return [
        {
          label: "Ver Detalle",
          icon: "pi pi-fw pi-eye",
          disabled:
            "Venta Ver Detalle" in this.auth.user.permissions ? false : true,
          to: `/ventas/${datos.id}/detalle`,
        },
        {
          label: "Descargar PDF",
          disabled:
            "Venta DescargarPDF" in this.auth.user.permissions ? false : true,
          icon: "pi pi-fw pi-file-pdf",
          command: () => this.imprimirVenta(datos.id),
        },
        /* {
          label: "Mostrar Factura",
          disabled: datos.facturado ? false : true,
          visible: false,
          icon: "pi pi-fw pi-file",
          command: () => this.mostrar_factura(datos),
        }, */
        {
          label: "Crear Factura",
          disabled:
            datos.estado == 0
              ? true
              : false || "Venta Facturar" in this.auth.user.permissions
              ? false
              : true,
          icon: "pi pi-fw pi-file",
          visible: datos.facturado == 0 ? true : false,
          command: () => this.ventaFacturar(datos.id),
        },
        {
          label: "Anular Venta",
          icon: "pi pi-fw pi-trash",
          disabled:
            datos.estado == 0
              ? true
              : false || "Venta Anular" in this.auth.user.permissions
              ? false
              : true,
          visible: datos.facturado == 1 ? false : true,
          command: () => this.anularVenta(datos.id),
        },
        {
          label: "Anular Factura",
          icon: "pi pi-fw pi-trash",
          tooltip: "Anular Factura",
          disabled:
            datos.estado == 0
              ? true
              : false || "Venta Anular" in this.auth.user.permissions
              ? false
              : true,
          visible: datos.facturado == 1 ? true : false,
          command: () => this.motivoAnulacionDialogConfirm(datos),
        },
        {
          label: "Cambiar Metodo de Pago",
          icon: "pi pi-fw pi-money-bill",
          disabled:
            datos.estado == 0 /* || datos.facturado == 1  */
              ? true
              : false /* || datos.tipo_pago_id == 2 */,
          command: () => this.ventaMetodoPago(datos),
        },
      ];
    },
    ventaMetodoPago(datos) {
      this.metodoPago = { ...datos };
      this.metodoPagoDialog = true;
    },
    cerrarModalMetodoPago() {
      this.metodoPagoDialog = false;
    },
    mostrar_factura(datos) {
      this.qr = datos.factura.qr;
      this.mostrar_qr = true;
    },
    ventaFacturar(id) {
      // paso todos los datos de la venta a mi componente de ventaFacturar
      this.ventaFactura = this.ventas.find((x) => x.id == id) || {};
      this.ventafacturaDialog = true;
    },
    ocultarDialog() {
      this.ventafacturaDialog = false;
    },
    motivoAnulacionDialogConfirm(datos) {
      this.anularVentaId = datos.id;
      this.motivoAnulacionDialog = true;
      this.enviando = false;
    },
    anularVentaFactura(id) {
      this.enviando = true;
      let datos = {
        id: id,
        motivoAnulacion: this.motivoAnulacionSelected,
      };
      this.ventaService.anularVenta(id, datos).then((response) => {
        if (response.status == 200) {
          this.$toast.add({
            severity: "success",
            summary: "Éxito",
            detail: response.mensaje,
            life: 3000,
          });
          this.cargarVentas();
          this.motivoAnulacionDialog = false;
          this.enviando = false;
        } else {
          this.$toast.add({
            severity: "error",
            summary: "Error",
            detail: response.mensaje,
            life: 3000,
          });
          this.enviando = false;
        }
      });
    },
    anularVenta(id) {
      this.$confirm.require({
        header: "CONFIRMACIÓN",
        message: "¿ESTÁS SEGURO DE ANULAR ESTA VENTA?",
        icon: "pi pi-exclamation-triangle",
        acceptLabel: "Si, Anular",
        rejectLabel: "No, Cancelar",
        acceptClass: "p-button-primary p-button-lg",
        rejectClass: "p-button-danger p-button-lg",
        acceptIcon: "pi pi-save",
        rejectIcon: "pi pi-times",
        accept: () => {
          this.ventaService.anularVenta(id).then((response) => {
            if (response.status == 200) {
              this.$toast.add({
                severity: "success",
                summary: "Éxito",
                detail: response.mensaje,
                life: 3000,
              });
              this.cargarVentas();
            } else {
              this.$toast.add({
                severity: "error",
                summary: "Error",
                detail: response.mensaje,
                life: 3000,
              });
            }
          });
        },
      });
    },
    nuevaVenta() {
      this.$router.push({ name: "nueva_venta" });
    },
    cargarVentas() {
      this.cargando = true;

      setTimeout(() => {
        /* this.ventaService.getAllVentas().then((response) => {
          this.ventas = response.ventas;
          this.cargando = false;
        }); */

        this.ventaService
          .getVentasPaginate({ dt_params: JSON.stringify(this.lazyParams) })
          .then((response) => {
            this.ventas = response.ventas.data || [];
            if (this.ventas.count > 0) {
              this.tipo_negocio = this.ventas[0].caja.tipo_negocio;
            }
            this.totalRecords = response.ventas.total;
            this.cargando = false;
          });
      }, 1000);
    },
    imprimirVenta(id) {
      this.ventaService
        .imprimirVenta(id)
        .then(() => {})
        .catch((error) => console.log(error));
    },
    textoFacturado(facturado) {
      if (facturado == 0) {
        return "NO";
      } else {
        return "SI";
      }
    },
    convertirNumeroGermanicFormat(numero) {
      return new Intl.NumberFormat("de-DE", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }).format(numero);
    },
    expandAll() {
      this.expandedRows = this.ventas.filter((p) => p.id);
    },
    collapseAll() {
      this.expandedRows = null;
    },
  },
};
</script>
<style scoped lang="scss">
@import "@/assets/demo/badges.scss";
.p-invalid {
  color: red;
}
</style>
